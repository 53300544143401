import { ImageType } from "types";

export function normalizeImageUrl(url: string) {
  if (!url) return url;
  const normalizeUrl = url
    .replace(
      "https://hardcover-staging.imgix.net",
      "https://storage.googleapis.com/hardcover-staging"
    )
    .replace(
      "https://hardcover.imgix.net",
      "https://storage.googleapis.com/hardcover"
    );
  return normalizeUrl;
}

export function railsUrl(external = false) {
  return external && typeof window === "undefined" && process.env.SSR_RAILS_URL
    ? process.env.SSR_RAILS_URL
    : process.env.NEXT_PUBLIC_RAILS_APP_URL;
}

export function signImageUrl(url: ImageType) {
  if (url.width && url.height) {
    return `${railsUrl()}/signed/images/crop?url=${url.url}&width=${
      url.width
    }&height=${url.height}&type=webp`;
  } else {
    return `${railsUrl()}/signed/images/image?url=${url.url}`;
  }
}
